<script setup lang="ts">
import { useCookie } from "#app";
import { useWindowSize } from "@vueuse/core";
import { useVuelidate } from "@vuelidate/core/dist/index.esm.js";
import { required, minLength, email } from "@vuelidate/validators";
import { COOKIE_KEYS } from "~~/utils/constants";

const { login } = useAuth();
const { $post } = useAxios();
const router = useRouter();

const username = ref(""),
  password = ref(""),
  firstName = ref(""),
  secondName = ref(""),
  e_mail = ref(""),
  errorMessages = ref([]),
  register = ref(false);

const rules = {
  username: { required, minLength: minLength(5), $autoDirty: true },
  password: { required, minLength: minLength(6), $autoDirty: true },
  firstName: { required, minLength: minLength(1), $autoDirty: true },
  secondName: { required, minLength: minLength(1), $autoDirty: true },
  e_mail: { required, minLength: minLength(5), email, $autoDirty: true },
};

const v$ = useVuelidate<any, any>(
  rules,
  { username, password, firstName, secondName, e_mail },
  { $rewardEarly: true }
);

const { width: windowWidth } = useWindowSize();

const containerWidth = ref(1280);
const formWidth = ref(0);

const isMobile = ref(windowWidth.value < 1068);

const isLogin = ref(true);

watch(isLogin, () => {
  errorMessages.value = [];
});

const mounted = ref(false);

onMounted(() => {
  if (isMobile.value) {
    formWidth.value = windowWidth.value - 32;
  } else {
    formWidth.value = (containerWidth.value * 7) / 12;
  }
  mounted.value = true;
});

function chamgeMod() {
  isLogin.value = !isLogin.value;
}

function onUsernameUpdate(e) {
  username.value = e;
}
function onPasswordUpdate(e) {
  password.value = e;
}
function onFirstNameUpdate(e) {
  firstName.value = e;
}
function onSecondNameUpdate(e) {
  secondName.value = e;
}
function onEmailUpdate(e) {
  e_mail.value = e;
}

const isSubmitLogin = ref(false);

async function onLoginSubmit() {
  const redirect = useCookie<RouteLocationNormalized>(COOKIE_KEYS.redirect);
  isSubmitLogin.value = true;
  try {
    errorMessages.value = [];
    const user = await login(username.value, password.value);
    isSubmitLogin.value = false;

    window.tracker.setUserID(user._id)
    window.tracker.setMetadata('firstName', user?.firstName);
    window.tracker.setMetadata('secondName', user?.secondName);
    window.tracker.setMetadata('email', user?.email);
    window.tracker.setMetadata('roles', user?.roles.join(', '));

    if (redirect?.value?.path) {
      router.push(`${redirect.value.path}`);
    } else {
      router.push(`/user`);
    }
  } catch (error) {
    isSubmitLogin.value = false;
    errorMessages.value.push({
      title: "Не удаётся войти",
      message: error.response.data.message,
    });
  }
}

const isSubmitRegister = ref(false);

async function onRegisterSubmit() {
  isSubmitRegister.value = true;
  try {
    errorMessages.value = [];
    v$.value.$touch();
    if (v$.value.$invalid) return;
    const submitForm = await $post("/api/v3/user", {
      firstName: firstName.value,
      secondName: secondName.value,
      userName: username.value,
      email: e_mail.value,
      password: password.value,
    });
    if (submitForm?.email) {
      register.value = true;
      const user = await login(username.value, password.value);
      isSubmitRegister.value = false;

      window.tracker.setUserID(user._id)
      window.tracker.setMetadata('firstName', user?.firstName);
      window.tracker.setMetadata('secondName', user?.secondName);
      window.tracker.setMetadata('email', user?.email);
      window.tracker.setMetadata('roles', user?.roles.join(', '));
    }
  } catch (error) {
    console.error(error);
    isSubmitRegister.value = false;
    if (Array.isArray(error.response.data))
      errorMessages.value.push({
        title: "Не удаётся зарегистрироваться",
        message: error.response.data[0].message,
      });
    else
      errorMessages.value.push({
        title: "Не удаётся зарегистрироваться",
        message: error.response.data.message,
      });
    // alert(`Что-то пошло не так. Попробуйте позже.`);
  }
}
</script>

<template>
  <nuxt-layout name="default">
    <div v-if="mounted">
      <r-section v-if="register === false">
        <div class="overflow-hidden rounded-xl transition-all duration-300 flex flex-row flex-nowrap" ref="form">
          <div v-if="isMobile" class="transition-all duration-500 flex flex-col flex-nowrap flex-justify max-w-full">
            <div class="pt-40 pb-44 text-center background-image-url bg-akcent relative">
              <div class="absolute -top-1 -left-3">
                <RBackgroundCubeMobile />
              </div>
              <div class="absolute top-1/3 inset-x-0 z-50 flex flex-col items-center justify-center">
                <h2 class="font-sans text-white text-2xl font-medium">
                  {{ isLogin? "Впервые у нас?": "Уже есть аккаунт?" }}
                </h2>
                <h5 class="font-sans text-white text-lg mt-6" v-html="
                  isLogin
                    ? 'Введите свои данные <br/> и создайте аккаунт'
                    : 'Введите свои данные для входа <br/> или используйте другие варианты'
                "></h5>

                <button
                  class="bg-white rounded-lg py-3 px-20 text-lg text-dark-gray font-sans font-medium mt-10 focus:outline-none"
                  @click="chamgeMod">
                  {{ isLogin? "Регистрация": "Войти" }}
                </button>
              </div>
            </div>
            <div class="transition-all duration-500 flex flex-row flex-nowrap flex-justify" :style="`transition-property: transform; box-sizing: content-box; transform: translate3d(-${isLogin ? formWidth + 'px' : '0px'
            }, 0px, 0px); height: 536px`">
              <RRegisterForm :style="`min-width: ${formWidth}px; width: ${formWidth}px`" class="overflow-hidden"
                :username="username" :password="password" :first-name="firstName" :second-name="secondName"
                :email="e_mail" :validator="v$" :is-submit-register="isSubmitRegister" :error-messages="errorMessages"
                @update:username="onUsernameUpdate" @update:password="onPasswordUpdate"
                @update:firstname="onFirstNameUpdate" @update:secondname="onSecondNameUpdate"
                @update:email="onEmailUpdate" @submit="onRegisterSubmit" />
              <RLoginForm :style="`min-width: ${formWidth}px; width: ${formWidth}px`" class="overflow-hidden"
                :username="username" :password="password" :is-submit-login="isSubmitLogin"
                :error-messages="errorMessages" @update:username="onUsernameUpdate" @update:password="onPasswordUpdate"
                @submit="onLoginSubmit" />
            </div>
          </div>
          <div v-else class="transition-all duration-500 flex flex-row flex-nowrap flex-justify" :style="`transition-property: transform; box-sizing: content-box; transform: translate3d(-${isLogin ? formWidth + 'px' : '0px'
          }, 0px, 0px); height: 576px`">
            <RRegisterForm :style="`min-width: ${formWidth}px; width: ${formWidth}px`" class="overflow-hidden"
              :username="username" :password="password" :first-name="firstName" :second-name="secondName"
              :email="e_mail" :validator="v$" :is-submit-register="isSubmitRegister" :error-messages="errorMessages"
              @update:username="onUsernameUpdate" @update:password="onPasswordUpdate"
              @update:firstname="onFirstNameUpdate" @update:secondname="onSecondNameUpdate"
              @update:email="onEmailUpdate" @submit="onRegisterSubmit" />
            <div class="pt-40 pb-44 text-center background-image-url bg-akcent relative" :style="`min-width: ${containerWidth - formWidth}px; width: ${containerWidth - formWidth
            }px`">
              <div class="absolute top-1/3 inset-x-0 z-50 flex flex-col items-center justify-center">
                <h2 class="font-sans text-white text-2xl font-medium mb-7">
                  {{ isLogin? "Впервые у нас?": "Уже есть аккаунт?" }}
                </h2>
                <h5 class="font-sans text-white text-lg2 mb-12" v-html="
                  isLogin
                    ? 'Введите свои данные и создайте аккаунт'
                    : 'Введите свои данные для входа <br/> или используйте другие варианты'
                "></h5>
                <button
                  class="bg-white rounded-lg py-3 w-66 text-lg text-dark-gray font-sans font-medium focus:outline-none"
                  @click="chamgeMod">
                  {{ isLogin? "Регистрация": "Войти" }}
                </button>
              </div>

              <div class="absolute top-10">
                <RBackgroundCube />
              </div>
            </div>
            <RLoginForm :style="`min-width: ${formWidth}px; width: ${formWidth}px`" class="overflow-hidden"
              :username="username" :password="password" :validator="v$" :error-messages="errorMessages"
              :is-submit-login="isSubmitLogin" @update:username="onUsernameUpdate" @update:password="onPasswordUpdate"
              @submit="onLoginSubmit" />
          </div>
        </div>
      </r-section>
      <RConfirmMail :email="e_mail" v-else></RConfirmMail>
    </div>
  </nuxt-layout>
</template>
