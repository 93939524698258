<script setup>
const props = defineProps({
  username: {
    type: String,
    required: true,
  },
  password: {
    type: String,
    required: true,
  },
  firstName: {
    type: String,
    required: true,
  },
  secondName: {
    type: String,
    required: true,
  },
  validator: {
    type: Object,
    required: true,
  },
  email: {
    type: String,
    required: true,
  },
  errorMessages: {
    type: Array,
    required: true,
  },
  isSubmitRegister: {
    type: Boolean,
    required: false,
  },
});

const continueRegister = ref(false);
const isOpenEye = ref(true);

const emit = defineEmits([
  "update:username",
  "update:password",
  "submit",
  "update:firstname",
  "update:secondname",
  "update:email",
]);

function onUsernameUpdate(e) {
  emit("update:username", e.target.value);
}

function onPasswordUpdate(e) {
  emit("update:password", e.target.value);
}

function onFirstNameUpdate(e) {
  emit("update:firstname", e.target.value);
}

function onSecondNameUpdate(e) {
  emit("update:secondname", e.target.value);
}

function onEmailUpdate(e) {
  emit("update:email", e.target.value);
}

function onSubmit() {
  emit("submit");
}

function CloseEye() {
  isOpenEye.value = false;
}

function OpenEye() {
  isOpenEye.value = true;
}

function onContinue() {
  if (
    props.validator.firstName.$error ||
    props.validator.secondName.$error ||
    props.validator.e_mail.$error
  ) {
    alert("error");
    continueRegister.value = false;
  } else if (
    props.firstName.length == 0 ||
    props.secondName.length == 0 ||
    props.email.length == 0
  ) {
    continueRegister.value = false;
    alert("Заполните все поля");
  } else {
    continueRegister.value = true;
  }
}
</script>

<template>
  <div class="bg-white flex flex-col text-center">
    <h1 class="font-sans text-4xl text-dark-gray mt-6">Регистрация</h1>
    <p class="text-lg text-dark-gray mt-4 sm:mt-6">
      Введите свои данные для создания аккаунта
    </p>
    <form
      class="flex flex-col space-y-4 justify-center mx-auto w-70 mt-12 sm:mt-20"
      @submit.prevent="onSubmit"
    >
      <div v-if="!continueRegister" class="flex flex-col space-y-4">
        <input
          type="text"
          name="firstName"
          :value="firstName"
          @input="onFirstNameUpdate"
          placeholder="Имя"
          :class="[validator.firstName.$error ? 'ring-1 ring-primary' : '']"
          @blur="validator.firstName.$commit"
          class="w-full rounded-lg h-12 bg-gray-normal px-5 font-normal leading-none focus:outline-none text-dark-gray focus:placeholder-gray-300"
        />
        <input
          type="text"
          name="secondName"
          :value="secondName"
          @input="onSecondNameUpdate"
          placeholder="Фамилия"
          :class="[validator.secondName.$error ? 'ring-1 ring-primary' : '']"
          @blur="validator.secondName.$commit"
          class="w-full rounded-lg h-12 bg-gray-normal px-5 font-normal leading-none focus:outline-none text-dark-gray focus:placeholder-gray-300"
        />
        <input
          type="email"
          name="email"
          :value="email"
          @input="onEmailUpdate"
          :class="[validator.e_mail.$error ? 'ring-1 ring-primary' : '']"
          @blur="validator.e_mail.$commit"
          placeholder="E-mail"
          class="w-full rounded-lg h-12 bg-gray-normal px-5 font-normal leading-none focus:outline-none text-dark-gray focus:placeholder-gray-300"
        />
        <div>
          <div
            class="rounded-lg w-full bg-primary text-center h-12 text-lg mt-3 pt-3 text-white cursor-pointer text-normal ease-out outline-none hover:bg-cherry hover:duration-300"
            @click="onContinue()"
          >
            Продолжить
          </div>
        </div>
      </div>

      <div
        v-else
        class="flex flex-col space-y-4 transition-all duration-1000"
        name="fade"
      >
        <input
          type="text"
          name="login"
          :value="username"
          @input="onUsernameUpdate"
          :class="[validator.username.$error ? 'ring-1 ring-primary' : '']"
          @blur="validator.username.$commit"
          placeholder="Логин"
          class="w-full rounded-lg h-12 bg-gray-normal px-5 font-normal leading-none focus:outline-none text-dark-gray focus:placeholder-gray-300"
        />
        <div class="relative">
          <p class="text-sm font-normal text-neutral text-left pb-1 font-sans">
            Пароль должен содержать не менее <br />
            6 символов
          </p>
          <input
            :type="[isOpenEye ? 'password' : 'text']"
            name="password"
            :value="password"
            @input="onPasswordUpdate"
            :class="[validator.password.$error ? 'ring-1 ring-primary' : '']"
            @blur="validator.password.$commit"
            placeholder="Пароль"
            class="mb-1 w-full rounded-lg h-12 bg-gray-normal pl-5 pr-14 font-normal leading-none focus:outline-none text-dark-gray focus:placeholder-gray-300"
          />

          <r-icon
            icon="visibility"
            :class="[!isOpenEye && password.length ? '' : 'hidden']"
            @click="OpenEye()"
            class="absolute right-6 cursor-pointer"
            style="top: 3.5rem; width: 17px; height: 13px"
          ></r-icon>

          <r-icon
            icon="invisible"
            @click="CloseEye()"
            class="absolute right-6 cursor-pointer"
            style="top: 3.4rem; width: 17px; height: 17px"
            :class="[isOpenEye && password.length ? '' : 'hidden']"
          ></r-icon>
        </div>

        <div>
          <r-button
            class="relative rounded-lg w-full text-center h-12 text-lg mt-3"
            :disabled="isSubmitRegister"
            :loading="isSubmitRegister"
          >
            Зарегистрироваться
          </r-button>
        </div>
      </div>
    </form>
    <article
      class="flex flex-col space-y-1 justify-center mx-auto w-70 mt-5 border border-primary rounded-lg p-4"
      v-for="error in errorMessages"
    >
      <h6 class="p-0 m-0 text-base">{{ error.title }}</h6>
      <p class="p-0 mt-0 text-12">{{ error.message }}</p>
    </article>
  </div>
</template>
