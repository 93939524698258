<script setup>
const props = defineProps({
  username: {
    type: String,
    required: true,
  },
  password: {
    type: String,
    required: true,
  },
  isSubmitLogin: {
    type: Boolean,
    required: true,
  },
  errorMessages: {
    type: Array,
    required: true,
  },
});

const isOpenEye = ref(true);
const facebookUrl = ref("");

const emit = defineEmits(["update:username", "update:password", "submit"]);

function onUsernameUpdate(e) {
  emit("update:username", e.target.value);
}

function onPasswordUpdate(e) {
  emit("update:password", e.target.value);
}

function onSubmit() {
  emit("submit");
}

function CloseEye() {
  isOpenEye.value = false;
}

function OpenEye() {
  isOpenEye.value = true;
}
function checkLoginState(e) {
  console.log(e);
  alert(JSON.stringify(e, null, 2));
}

onMounted(() => {
  facebookUrl.value = `https://www.facebook.com/login/reauth.php?app_id=463191845423162&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fv2.12%2Fdialog%2Foauth%3Fprotocol%3Doauth2%26response_type%3Dtoken%26client_id%3D463191845423162%26redirect_uri%3D${window.location.origin}%252Fuser%252Ffacebook%26scope%3Dpublic_profile%2Bemail%26state%3D3nGNRX3O024eIdt01IwcU%26ret%3Dlogin%26fbapp_pres%3D0%26logger_id%3Dab83946f-4ba6-43fc-a2f7-b58dcdf4fce4&cancel_url=${window.location.origin}%2Fuser%2Ffacebook%3Ferror%3Daccess_denied%26error_code%3D200%26error_description%3DPermissions%2Berror%26error_reason%3Duser_denied%26state%3D3nGNRX3O024eIdt01IwcU%23_%3D_&display=page&locale=ru_RU&pl_dbl=0`;
});
</script>

<template>
  <div class="bg-white flex flex-col text-center">
    <h1 class="font-sans text-4xl text-dark-gray mt-6">Авторизация</h1>
    <div class="flex flex-row space-x-8 justify-center mt-14">
      <div class="max-w-12">
        <a :href="facebookUrl">
          <button>
            <r-icon class="w-12 h-12" icon="facebook-canonical" />
          </button>
        </a>
      </div>
      <!--
            TODO
            Авторизация через гугл и эпл
           -->
      <!-- <div class="max-w-12">
            <button>
              <r-icon class="w-12 h-12" icon="google" />
            </button>
          </div>
          <div class="max-w-12">
            <button>
              <r-icon class="w-12 h-12" icon="apple" />
            </button>
          </div> -->
    </div>
    <h6 class="font-sans text-dark-gray text-lg mt-7">
      Или используйте ваш аккаунт
    </h6>
    <form
      class="flex flex-col space-y-4 justify-center mx-auto w-70 mt-5"
      @submit.prevent="onSubmit"
    >
      <input
        type="text"
        name="login"
        :value="username"
        @change="onUsernameUpdate"
        placeholder="Имя пользователя"
        class="w-full rounded-lg h-12 bg-gray-normal px-5 text-lg font-normal leading-none focus:outline-none text-dark-gray focus:placeholder-gray-300"
      />
      <span class="relative">
        <input
          :type="[isOpenEye ? 'password' : 'text']"
          name="password"
          :value="password"
          @change="onPasswordUpdate"
          placeholder="Пароль"
          class="w-full rounded-lg h-12 bg-gray-normal pl-5 pr-14 text-lg font-normal leading-none focus:outline-none text-dark-gray focus:placeholder-gray-300"
        />
        <r-icon
          icon="visibility"
          :class="[!isOpenEye && password.length ? '' : 'hidden']"
          @click="OpenEye()"
          class="absolute right-6 cursor-pointer"
          style="top: 1.1rem; width: 17px; height: 13px"
        ></r-icon>

        <r-icon
          icon="invisible"
          @click="CloseEye()"
          class="absolute right-6 cursor-pointer"
          style="top: 1rem; width: 17px; height: 17px"
          :class="[isOpenEye && password.length ? '' : 'hidden']"
        ></r-icon>
      </span>

      <p
        v-if="errorMessages.length"
        class="text-primary text-base font-normal font-sans text-left"
      >
        {{errorMessages[0].message}}
      </p>
      <div>
        <r-button
          class="rounded-lg w-full text-center h-12 mt-2"
          :disabled="isSubmitLogin"
          :loading="isSubmitLogin"
        >
          Войти
        </r-button>
      </div>

      <router-link
        to="/recovery-account"
        class="text-neutral font-normal text-lg"
      >
        Забыли пароль?
      </router-link>
    </form>
  </div>
</template>
