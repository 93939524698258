<template>
  <div class="absolute">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="435.001"
      height="296.033"
      viewBox="0 0 435.001 296.033"
    >
      <g
        id="Сгруппировать_170"
        data-name="Сгруппировать 170"
        transform="translate(0.001 -0.002)"
      >
        <path
          id="Контур_158"
          data-name="Контур 158"
          d="M45.653,95.805H82.815A12.991,12.991,0,0,0,95.806,82.814V12.991A12.991,12.991,0,0,0,82.815,0H12.991A12.991,12.991,0,0,0,0,12.991Z"
          transform="matrix(-0.899, -0.438, 0.438, -0.899, 393.002, 296.035)"
          fill="#9E2626"
          class="animation6"
        />
        <path
          id="Пересечение_4"
          data-name="Пересечение 4"
          d="M56.847,110.988,0,83.262V10A10,10,0,0,1,10,0H138.714L90.181,99.51a24.913,24.913,0,0,1-33.334,11.478Z"
          transform="translate(12)"
          fill="#9E2626"
          class="animation7"
        />
        <path
          id="Контур_159"
          data-name="Контур 159"
          d="M7.49,55.239H47.749a7.49,7.49,0,0,0,7.49-7.49V7.49A7.49,7.49,0,0,0,47.749,0H7.49A7.49,7.49,0,0,0,0,7.49V47.749A7.49,7.49,0,0,0,7.49,55.239Z"
          transform="matrix(-0.899, -0.438, 0.438, -0.899, 49.648, 253.002)"
          fill="#9E2626"
          class="animation8"
        />
        <path
          id="Контур_163"
          data-name="Контур 163"
          d="M4.4,32.475H28.072a4.4,4.4,0,0,0,4.4-4.4V4.4a4.4,4.4,0,0,0-4.4-4.4H4.4A4.4,4.4,0,0,0,0,4.4V28.071A4.4,4.4,0,0,0,4.4,32.475Z"
          transform="matrix(-0.899, -0.438, 0.438, -0.899, 86.871, 275.406)"
          fill="#9E2626"
          class="animation9"
        />
        <path
          id="Контур_160"
          data-name="Контур 160"
          d="M6.708,49.469H42.761a6.708,6.708,0,0,0,6.708-6.708V6.708A6.708,6.708,0,0,0,42.761,0H6.708A6.708,6.708,0,0,0,0,6.708V42.761A6.708,6.708,0,0,0,6.708,49.469Z"
          transform="matrix(-0.899, -0.438, 0.438, -0.899, 359.15, 141.002)"
          fill="#9E2626"
          class="animation10"
        />
        <path
          id="Контур_162"
          data-name="Контур 162"
          d="M4.038,29.778h21.7a4.038,4.038,0,0,0,4.038-4.038V4.038A4.038,4.038,0,0,0,25.74,0H4.038A4.038,4.038,0,0,0,0,4.038v21.7A4.038,4.038,0,0,0,4.038,29.778Z"
          transform="matrix(-0.899, -0.438, 0.438, -0.899, 354.617, 63.002)"
          fill="#9E2626"
          class="animation11"
        />
      </g>
    </svg>
  </div>
</template>
<style scoped>
.animation6 {
  animation-name: example6;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes example6 {
  0% {
    transform: matrix(-0.899, -0.438, 0.438, -0.899, 393.002, 296.035);
  }
  50% {
    transform: matrix(-0.899, -0.438, 0.438, -0.899, 393.002, 306.035);
  }
  100% {
    transform: matrix(-0.899, -0.438, 0.438, -0.899, 393.002, 296.035);
  }
}
.animation7 {
  animation-name: example7;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes example7 {
  50% {
    transform: translate(12);
  }
}

.animation8 {
  animation-name: example8;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes example8 {
  0% {
    transform: matrix(-0.899, -0.438, 0.438, -0.899, 49.648, 253.002);
  }
  50% {
    transform: matrix(-0.899, -0.438, 0.438, -0.899, 49.648, 243.002);
  }
  100% {
    transform: matrix(-0.899, -0.438, 0.438, -0.899, 49.648, 253.002);
  }
}

.animation9 {
  animation-name: example9;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes example9 {
  0% {
    transform: matrix(-0.899, -0.438, 0.438, -0.899, 86.871, 275.406);
  }
  50% {
    transform: matrix(-0.899, -0.438, 0.438, -0.899, 86.871, 285.406);
  }
  100% {
    transform: matrix(-0.899, -0.438, 0.438, -0.899, 86.871, 275.406);
  }
}

.animation10 {
  animation-name: example10;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes example10 {
  0% {
    transform: matrix(-0.899, -0.438, 0.438, -0.899, 359.15, 141.002);
  }
  50% {
    transform: matrix(-0.899, -0.438, 0.438, -0.899, 359.15, 151.002);
  }
  100% {
    transform: matrix(-0.899, -0.438, 0.438, -0.899, 359.15, 141.002);
  }
}

.animation11 {
  animation-name: example11;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes example11 {
  0% {
    transform: matrix(-0.899, -0.438, 0.438, -0.899, 354.617, 63.002);
  }
  50% {
    transform: matrix(-0.899, -0.438, 0.438, -0.899, 354.617, 73.002);
  }
  100% {
    transform: matrix(-0.899, -0.438, 0.438, -0.899, 354.617, 63.002);
  }
}
</style>
<script>
export default {
  name: 'RBackgroundCube'
}
</script>
