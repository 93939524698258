<template>
  <div class="absolute">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="527.236"
      height="537"
      viewBox="0 0 527.236 537"
    >
      <g id="img-auth" transform="translate(-80.834 0)">
        <path
          id="Контур_158"
          data-name="Контур 158"
          d="M12.991,0H82.814A12.991,12.991,0,0,1,95.805,12.991V82.814A12.991,12.991,0,0,1,82.814,95.805H12.991A12.991,12.991,0,0,1,0,82.814V12.991A12.991,12.991,0,0,1,12.991,0Z"
          transform="matrix(-0.899, 0.438, -0.438, -0.899, 608.07, 86.109)"
          fill="#9E2626"
          class="animation0"
        />
        <path
          id="Контур_159"
          data-name="Контур 159"
          d="M7.49,0H47.749a7.49,7.49,0,0,1,7.49,7.49V47.749a7.49,7.49,0,0,1-7.49,7.49H7.49A7.49,7.49,0,0,1,0,47.749V7.49A7.49,7.49,0,0,1,7.49,0Z"
          transform="matrix(-0.899, 0.438, -0.438, -0.899, 189.83, 155.094)"
          fill="#9E2626"
          class="animation1"
        />
        <path
          id="Контур_163"
          data-name="Контур 163"
          d="M4.4,0H28.072a4.4,4.4,0,0,1,4.4,4.4V28.071a4.4,4.4,0,0,1-4.4,4.4H4.4a4.4,4.4,0,0,1-4.4-4.4V4.4A4.4,4.4,0,0,1,4.4,0Z"
          transform="matrix(-0.899, 0.438, -0.438, -0.899, 217.83, 91.209)"
          fill="#9E2626"
          class="animation2"
        />
        <path
          id="Контур_160"
          data-name="Контур 160"
          d="M9.164,0H58.421a9.164,9.164,0,0,1,9.164,9.164V58.421a9.164,9.164,0,0,1-9.164,9.164H9.164A9.164,9.164,0,0,1,0,58.421V9.164A9.164,9.164,0,0,1,9.164,0Z"
          transform="matrix(-0.899, 0.438, -0.438, -0.899, 589.203, 493.745)"
          fill="#9E2626"
          class="animation3"
        />
        <path
          id="Контур_162"
          data-name="Контур 162"
          d="M6.73,0H42.9a6.73,6.73,0,0,1,6.73,6.73V42.9a6.73,6.73,0,0,1-6.73,6.73H6.73A6.73,6.73,0,0,1,0,42.9V6.73A6.73,6.73,0,0,1,6.73,0Z"
          transform="matrix(-0.899, 0.438, -0.438, -0.899, 420.191, 439.606)"
          fill="#9E2626"
          class="animation4"
        />

        <path
          id="Контур_161"
          data-name="Контур 161"
          d="M24.929,0H158.915a24.929,24.929,0,0,1,24.929,24.929V158.914a24.929,24.929,0,0,1-24.929,24.929H24.929A24.929,24.929,0,0,1,0,158.914V24.929A24.929,24.929,0,0,1,24.929,0Z"
          transform="matrix(-0.899, 0.438, -0.438, -0.899, 255.829, 555.237)"
          fill="#9E2626"
          class="animation5"
        />
      </g>
    </svg>
  </div>
</template>
<style scoped>
.animation0 {
  animation-name: example0;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes example0 {
  0% {
    transform: matrix(-0.899, 0.438, -0.438, -0.899, 608.07, 86.109);
  }
  50% {
    transform: matrix(-0.899, 0.438, -0.438, -0.899, 608.07, 116.109);
  }
  100% {
    transform: matrix(-0.899, 0.438, -0.438, -0.899, 608.07, 86.109);
  }
}
.animation1 {
  animation-name: example1;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes example1 {
  0% {
    transform: matrix(-0.899, 0.438, -0.438, -0.899, 189.83, 155.094);
  }
  50% {
    transform: matrix(-0.899, 0.438, -0.438, -0.899, 189.83, 125.094);
  }
  100% {
    transform: matrix(-0.899, 0.438, -0.438, -0.899, 189.83, 155.094);
  }
}

.animation2 {
  animation-name: example2;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes example2 {
  0% {
    transform: matrix(-0.899, 0.438, -0.438, -0.899, 217.83, 91.209);
  }
  50% {
    transform: matrix(-0.899, 0.438, -0.438, -0.899, 217.83, 65.209);
  }
  100% {
    transform: matrix(-0.899, 0.438, -0.438, -0.899, 217.83, 91.209);
  }
}

.animation3 {
  animation-name: example3;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes example3 {
  0% {
    transform: matrix(-0.899, 0.438, -0.438, -0.899, 589.203, 493.745);
  }
  50% {
    transform: matrix(-0.899, 0.438, -0.438, -0.899, 589.203, 463.745);
  }
  100% {
    transform: matrix(-0.899, 0.438, -0.438, -0.899, 589.203, 493.745);
  }
}

.animation4 {
  animation-name: example4;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes example4 {
  0% {
    transform: matrix(-0.899, 0.438, -0.438, -0.899, 420.191, 439.606);
  }
  50% {
    transform: matrix(-0.899, 0.438, -0.438, -0.899, 420.191, 409.606);
  }
  100% {
    transform: matrix(-0.899, 0.438, -0.438, -0.899, 420.191, 439.606);
  }
}

.animation5 {
  animation-name: example5;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes example5 {
  0% {
    transform: matrix(-0.899, 0.438, -0.438, -0.899, 255.829, 555.237);
  }
  50% {
    transform: matrix(-0.899, 0.438, -0.438, -0.899, 255.829, 525.237);
  }
  100% {
    transform: matrix(-0.899, 0.438, -0.438, -0.899, 255.829, 555.237);
  }
}
</style>
<script>
export default {
  name: 'RBackgroundCube'
}
</script>
